<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลประเภทสถานที่ตั้ง</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="mb-4">
            <label class="form-label">ชื่อประเภทสถานที่ตั้ง</label>
            <base-input name="name" :rules="{required: true}" placeholder="ชื่อ" v-model="item.name"></base-input>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
export default {
  name: 'location-view-form-location',
  props: [
    'item'
  ]
}
</script>
